import React from 'react'
import { Bar } from 'react-chartjs-2'
import {CategoryScale} from 'chart.js';
Chart.register(CategoryScale);
import Chart from 'chart.js/auto';

function BarChart({data, org, labels }) {
    const showSubtitle = !data.find(v => v > 0);

    return (
        <div>
            <Bar
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: org,
                            data:  data,
                            backgroundColor: [
                                '#E31937',
                            ],
                            borderColor: [
                                '#E31937',
                            ],
                            borderWidth: 3,
                        },
                    ],
                }}
                height={325}
                options={{
                    layout: {
                        padding: 0
                    },
                    backgroundColor: 'orange',
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: {
                            grid: {
                                display: false,
                                color: '#000',
                                z: -9
                            },
                            ticks: {
                                color: '#888',
                                font: {
                                    size: 22,
                                },

                            },
                        },
                        x: {
                            ticks: {
                                color: '#000',
                                font: {
                                    size: 16
                                }
                            }
                        }
                    },
                    legend: {
                        labels: {
                            font: {
                                size: 18,
                                color: 'green'
                            },
                        },
                    },
                    plugins: {
                        scales: {
                        },
                        legend: {
                            align: 'start',
                            display: false,
                            labels: {
                                font: {
                                    size: 18
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                size: 20
                            },
                            backgroundColor: '#eee',
                            bodyColor: '#000',
                            titleColor: 'black',
                            titleFont: {
                                weight: 'bold',
                                size: 18
                            },
                            mode: 'index',
                            intersect: true
                        },
                        title: {
                            display: true,
                            align: 'start',
                            text: '',
                            color: '#4c8ff5', 
                            font: {
                                size: 18
                            }
                        },
                        subtitle : {
                            display: showSubtitle,
                            align: 'center',
                            text: 'No Data for Interval',
                            font: {
                                size: 22,
                            },
                            color: '#555'
                        },
                    }
                }}
            />
        </div>
    )
}

export default BarChart