import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import BarChart from "./barChart";
import BigNumbers from "./bigNumbers";
import { DataDisplay } from './dataDisplay';
import { DateRange } from 'react-date-range';
import { getBeginningOfNextDay, getCurrentYear, getDatesOfLastWeek, getPreviousDay, getTomorrow, getYesterday, toDayAndMonth, toFullDateString, toMonthAndYear } from "../dates";
import Modal from "./modal";
import { useContext, useEffect, useRef, useState } from "react";
import '../styles/fanCast.css';
import '../styles/prPlus.css';
import { DateTime } from "luxon";

const PRPlus = () => {
    const { firstDay, lastDay } = getDatesOfLastWeek();
    const authCtx = useContext(AuthContext);
    const [partnerCounts, setPartnerCounts] = useState([]);
    const [partnerNames, setPartnerNames] = useState([]);
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [lastWeekSelected, setLastWeekSelected] = useState(true);
    const [lastMonthSelected, setLastMonthSelected] = useState(false);
    const [lastQuarterSelected, setLastQuarterSelected] = useState(false);
    const [currentYearSelected, setCurrentYearSelected] = useState(false);
    const [allTimeSelected, setAllTimeSelected] = useState(false);
    const [customDateRangeSelected, setCustomDateRangeSelected] = useState(false);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [partnerPRCountValues, setPartnerPRCountValues] = useState([]);
    const [partnerPRCountDates, setPartnerPRCountDates] = useState([]);
    const [totalPRConnectCount, setTotalPRConnectCount] = useState(0);
    const [totalUserStreamCount, setTotalUserStreamCount] = useState(0);
    const [userStreamDates, setUserStreamDates] = useState([]);
    const [userStreamValues, setUserStreamValues] = useState([]);
    const [logoUrls, setLogoUrls] = useState([]);
    const [logoNames, setLogoNames] = useState([]);

    const intialCustomDateRange = {
        startDate: getYesterday(),
        endDate: new Date(),
        key: 'selection'
    };
    const [customDateSelection, setCustomDateSelection] = useState([intialCustomDateRange]);

    const dateRangePickerRef = useRef();

    let isMonthlyTimeFrame = false;

    let showSubtitlePartner = !partnerCounts.length;
    let showSubtitleOrg = !userStreamValues.length;

    const setLastWeek = () => {
        if (!lastWeekSelected) {
            setLastWeekSelected(true);
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setAllTimeSelected(false)
            setCurrentYearSelected(false)
            setCustomDateRangeSelected(false)
        }
        setLastWeekDates();
        setShowDateRangePicker(false);
    };
    let lastWeekButtonClass = lastWeekSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setLastMonth = () => {
        if (!lastMonthSelected) {
            setLastMonthSelected(true);
            setLastWeekSelected(false)
            setLastQuarterSelected(false)
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
            setCustomDateRangeSelected(false)
        }
        setLastMonthDates();
        setShowDateRangePicker(false);
    };
    let lastMonthButtonClass = lastMonthSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setLastQuarter = () => {
        if (!lastQuarterSelected) {
            setLastQuarterSelected(true);
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
            setCustomDateRangeSelected(false)
        }
        setLastQuarterDates();
        setShowDateRangePicker(false);
    };
    let lastQuarterButtonClass = lastQuarterSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setCurrentYear = () => {
        if (!currentYearSelected) {
            setCurrentYearSelected(true);
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setAllTimeSelected(false)
            setCustomDateRangeSelected(false)
        }
        setCurrentYearDates();
        setShowDateRangePicker(false);
    };
    let currentYearButtonClass = currentYearSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setAllTime = () => {
        if (!allTimeSelected) {
            setAllTimeSelected(true)
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setCurrentYearSelected(false);
            setCustomDateRangeSelected(false)
        }
        setAllTimeDates();
        setShowDateRangePicker(false);
    };
    let allTimeButtonClass = allTimeSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setCustomDateRange = () => {
        if (!customDateRangeSelected) {
            setCustomDateRangeSelected(true);
            setCustomDates(intialCustomDateRange);
            setLastWeekSelected(false);
            setLastMonthSelected(false);
            setLastQuarterSelected(false);
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
        }
        setShowDateRangePicker(!showDateRangePicker);
    };
    let customDateRangeButtonClass = customDateRangeSelected ? "text-danger btn  bg-white" : "text-dark btn";

    function setLastWeekDates() {
        const {firstDay, lastDay} = getDatesOfLastWeek();

        setStartDate(firstDay);
        setEndDate(lastDay);
    }

    function setLastMonthDates() {
        const date = new Date();

        const firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        // Really zero hours on the first day of this month
        const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);

        setStartDate(firstDayPrevMonth);
        setEndDate(lastDayPrevMonth);
    }

    function setLastQuarterDates() {
        const now = new Date();
        let currentQuarter = Math.floor(now.getMonth() / 3 + 1);
        const currentYear = now.getFullYear();

        let qtrStartDate;
        let qtrEndDate;

        switch (currentQuarter) {
            case 1:
                // Oct 1 - end of Dec 31 previous year
                qtrStartDate = new Date(currentYear - 1, 9, 1);
                qtrEndDate = new Date(currentYear, 0, 1);
                break;
            case 2:
                // Jan 1 - end of Mar 31
                qtrStartDate = new Date(currentYear, 0, 1);
                qtrEndDate = new Date(currentYear, 3, 1);
                break;
            case 3:
                // Apr 1 - end of Jun 30
                qtrStartDate = new Date(currentYear, 3, 1);
                qtrEndDate = new Date(currentYear, 6, 1);
                break;
            case 4:
                // Jul 1 - end of Sep 30
                qtrStartDate = new Date(currentYear, 6, 1);
                qtrEndDate = new Date(currentYear, 9, 1);
                break;
        }

        setStartDate(qtrStartDate);
        setEndDate(qtrEndDate);
    }

    function setCurrentYearDates() {
        const currentYearStart = new Date(new Date().getFullYear(), 0, 1);
        const currentYearEnd = getTomorrow();

        setStartDate(currentYearStart);
        setEndDate(currentYearEnd);
    }

    function setAllTimeDates() {
        const allTimeStart = new Date(2022, 0, 1);
        const now = getTomorrow();

        setStartDate(allTimeStart);
        setEndDate(now);
    }

    function setCustomDates(pickerSelection) {
        setCustomDateSelection([pickerSelection]);

        setStartDate(pickerSelection.startDate);
        setEndDate(getBeginningOfNextDay(pickerSelection.endDate));
    }

    function getPRPlusPurchasesByDay(startDate, endDate) {
        API.getPRPlusPurchasesByDay(startDate, endDate, authCtx.token).then((data) => {
            const newData = data.counts;

            const resultPRConnectDates = newData.map(data => toDayAndMonth(data.purchasedAt));
            const resultPRConnectValues = newData.map(data => data.count);

            const totalPRConnectTemp = newData.reduce((sum, item) => sum + item.count, 0);
            setTotalPRConnectCount(totalPRConnectTemp);

            setPartnerPRCountValues(resultPRConnectValues);
            setPartnerPRCountDates(resultPRConnectDates);
        });
    }

    function getPRConnectPartnerBreakdown(startDate, endDate) {
        API.getPRConnectPartnerBreakdown(startDate, endDate, authCtx.token).then((data) => {
            const topPartners = data.partners.slice(0,3);
            const otherPartners = data.partners.slice(3);
            const resultNames = topPartners.map(p => p.partner);
            const resultValues = topPartners.map(p => p.count);

            const otherTotal = otherPartners.reduce((sum, item) => sum + item.count, 0);
            if (otherTotal) {
                resultNames.push('Other');
                resultValues.push(otherTotal);
            }

            setPartnerNames(resultNames);
            setPartnerCounts(resultValues);
        });
    }

    function getPartnerLogos() {
        API.getPartnerLogos(authCtx.token).then((data) => {
            const names = data.logos.map(p => p.name);
            const urls = data.logos.map(p => p.logoUrl);
            setLogoNames(names);
            setLogoUrls(urls);
        });
    }

    function getUserStreamData(startDate, endDate) {
        API.getUserStreamData(startDate, endDate, authCtx.token).then((data) => {
            const userStreamData = data.dates;

            const totalDays = Math.round(DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), ["days"]).toObject().days);
            isMonthlyTimeFrame = totalDays > 365;

            const resultUserStreamDates = userStreamData.map(item => formatISODateForDisplay(item.date));
            setUserStreamDates(resultUserStreamDates);

            const resultUserStreamValues = userStreamData.map(item => item.streams);
            setUserStreamValues(resultUserStreamValues);

            const totalUserStreamTemp = userStreamData.reduce((sum, item) => sum + item.streams, 0);
            setTotalUserStreamCount(totalUserStreamTemp);
        });
    }

    function getLogoUrl(partner) {
        let index = logoNames.indexOf(partner);

        if (index >= 0) {
            return logoUrls[index];
        }
    }

    function formatISODateForDisplay(isoDate) {
        const date = new Date(isoDate);

        if (isMonthlyTimeFrame) {
            return toMonthAndYear(date);
        } else {
            return toDayAndMonth(date);
        }
    }

    function handlePageClick(e) {
        // If the user clicks outside the date range picker, dismiss the date range picker.
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(e.target)) {
            setShowDateRangePicker(false);
        }
    }

    useEffect(() => {
        getPartnerLogos();
    }, []);

    useEffect(() => {
        getPRConnectPartnerBreakdown(startDate, endDate);
        getPRPlusPurchasesByDay(startDate, endDate);
        getUserStreamData(startDate, endDate);
    }, [startDate, endDate])

    function getTimeFrameContent() {
        let currentYear = getCurrentYear();
        let displayedEndDate = getPreviousDay(endDate);
        let showFullTimeFrameDates = startDate.getFullYear() < currentYear || displayedEndDate < currentYear;
        let timeFrameStartDateContent = showFullTimeFrameDates ? toFullDateString(startDate) : toDayAndMonth(startDate);
        let timeFrameEndDateContent = showFullTimeFrameDates ? toFullDateString(displayedEndDate) : toDayAndMonth(displayedEndDate);
        return <>{timeFrameStartDateContent} - {timeFrameEndDateContent}</>;
    }

    function getDateRangePickerContent() {
        if (showDateRangePicker) {
            return <>
                <div ref={dateRangePickerRef} className="col-4 rounded-4 bg-warning" style={{
                    backgroundColor: '#000',
                    marginLeft: '5%',
                    margin: '10px',
                    width: '35%',
                    alignItems: 'flex-end',
                    justifyContent: 'right',
                }}>
                    <DateRange
                      inline
                      editableDateInputs={true}
                      onChange={item => setCustomDates(item.selection)}
                      moveRangeOnFirstSelection={true}
                      ranges={customDateSelection}
                      endDate={endDate}
                      months={2}
                      direction="horizontal"
                      rangeColors={['#696974']}
                      weekStartsOn={0}
                      showPreview={true}
                      showDateDisplay={true}
                      autoFocus={true}
                      dragSelectionEnabled={true}
                      staticRanges={[]}
                    />
                </div>
            </>;
        } else {
            return '';
        }
    }

    function getPartnerCountContent() {
        const rows = [];
        for (let i = 0; i < partnerNames.length; i++) {
            rows.push(<PartnerCount
              name={partnerNames[i]}
              count={partnerCounts[i]}
              logoUrl={getLogoUrl(partnerNames[i])}
              key={partnerNames[i]}
            />)
        }
        if (rows.length) {
            return <>{rows}</>;
        } else {
            return <div className="text-dark">No data for interval</div>
        }
    }

    function PartnerCount({name, count, logoUrl}) {
        return  <div className="col-sm py-1">
                    <DataDisplay
                      title={name}
                      dataValue={count}
                      logoUrl={logoUrl}
                      valueColor={'#E31937'}
                    />
                </div>
    }

    return (
        <div onClick={handlePageClick} className=""
             style={{backgroundColor: 'white', height: '100%', alignContent: 'right', fontSize: '1.0em'}}>

            <div className="container">
                <h3 className="text-dark" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    marginBottom: '45px',
                    marginTop: '30px',
                    paddingLeft: '12px',
                }}>Pocket Radar PLUS</h3>
                <h3 className="text-dark" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    marginBottom: '8px',
                    paddingLeft: '12px',
                }}>Timeframe</h3>
                <div className="row pb-3">
                    <div className="col-8 py-1 " id="dateRange" hidden="">
                        <div>
                            <h3 className="text-dark py-2" style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                paddingLeft: '12px',
                            }}>  {getTimeFrameContent()} </h3>
                        </div>
                        <div className="btn-toolbar col-md-12" role="toolbar">
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setLastWeek}
                                    aria-pressed="true" className={lastWeekButtonClass}>Last Week
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setLastMonth}
                                    aria-pressed="true" className={lastMonthButtonClass}>Last Month
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setLastQuarter}
                                    aria-pressed="true" className={lastQuarterButtonClass}>Last Quarter
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setCurrentYear}
                                    aria-pressed="true" className={currentYearButtonClass}>{new Date().getFullYear()}
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setAllTime}
                                    aria-pressed="true" className={allTimeButtonClass}>All Time
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setCustomDateRange}
                                    aria-pressed="true" className={customDateRangeButtonClass}>Custom
                            </button>
                        </div>
                        {getDateRangePickerContent()}
                    </div>
                    <br/><br/><br/>
                    <div className="col-sm py-1"/>
                    {/*<div className="col-sm py-1">*/}
                    {/*    <img*/}
                    {/*        src="https://img.favpng.com/18/3/17/app-icon-download-icon-essential-icon-png-favpng-GAajYzjixCMbMVVFAsYnVHkFy.jpg"*/}
                    {/*        height="25px"/>*/}
                    {/*    <button type="button" className="text-dark btn">Download</button>*/}
                    {/*</div>*/}
                </div>
            </div>
            <br/><br/>
            <div className="container text-light">
                <div className="row py-2">
                    <div className="col-sm py-1" style={{
                        paddingLeft: '25px'
                    }}>
                        <h3 className="text-dark" style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}>Purchases</h3>
                        <div className="px-4 py-2">
                            <BigNumbers title={'Total Purchases'} value={totalPRConnectCount}/>
                            <BarChart title={'Org Partner Breakdown'}
                                      labels={partnerPRCountDates}
                                      data={partnerPRCountValues}
                                      tooltip={'# of Partner Purchases'}
                                      org='Pocket Radar PLUS'
                            />
                        </div>
                    </div>

                    <div className="col-sm py-1">
                        <h3 className="text-dark" style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}>Streams</h3>
                        <div className="px-4 py-2">
                            <BigNumbers title={'Total Streams'} value={totalUserStreamCount.toLocaleString()}/>
                            <BarChart title={'Org Event Purchases'}
                                      labels={userStreamDates}
                                      data={userStreamValues}
                                      tooltip={'# of Purchases by Org'}
                                      showSubtitle={showSubtitleOrg}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-light">
                <div className="row pt-3">
                    <h3 className="text-dark" style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: '32px',
                        fontWeight: 'bold',
                        marginBottom: '25px',
                        marginTop: '40px'
                    }}>Partner Connections</h3>
                    {getPartnerCountContent()}
                </div>
            </div>
            <Modal/>
            <br/><br/><br/><br/>
        </div>
    )
}

export default PRPlus;