import API from "../ApiInterface";
import AuthContext from "../store/auth-context";
import BarChart from "./barChart";
import BarChartStack from './barChartStacked';
import BigNumbers from "./bigNumbers";
import { DataDisplay } from './dataDisplay';
import { DateRange } from 'react-date-range';
import { getBeginningOfNextDay, getCurrentYear, getDatesOfLastWeek, getPreviousDay, getTomorrow, getYesterday, toDayAndMonth, toFullDateString, toMonthAndYear } from "../dates";
import Modal from "./modal";
import { useContext, useEffect, useRef, useState } from "react";

import '../styles/fanCast.css';
import '../styles/prPlus.css';
import './dateRangePicker.css';
import { DateTime } from "luxon";

const FanCast = () => {
    const { firstDay, lastDay } = getDatesOfLastWeek();
    const authCtx = useContext(AuthContext);
    const [orgNames, setOrgNames] = useState([]);
    const [orgPassCounts, setOrgPassCounts] = useState([]);
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [lastWeekSelected, setLastWeekSelected] = useState(true);
    const [lastMonthSelected, setLastMonthSelected] = useState(false);
    const [lastQuarterSelected, setLastQuarterSelected] = useState(false);
    const [currentYearSelected, setCurrentYearSelected] = useState(false);
    const [allTimeSelected, setAllTimeSelected] = useState(false);
    const [customDateRangeSelected, setCustomDateRangeSelected] = useState(false);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [totalGameStreamCount, setTotalGameStreamCount] = useState(0);
    const [totalDayPass, setTotalDayPass] = useState(0);
    const [totalEventPass, setTotalEventPass] = useState(0);

    const [dayPassDates, setDayPassDates] = useState();
    const [eventPassDates, setEventPassDates] = useState();

    const [fullAccessPassOrg1Name, setFullAccessPassOrg1Name] = useState();
    const [fullAccessPassOrg2Name, setFullAccessPassOrg2Name] = useState();
    const [fullAccessPassOrg3Name, setFullAccessPassOrg3Name] = useState();
    const [fullAccessPassOrg1Count, setFullAccessPassOrg1Count] = useState();
    const [fullAccessPassOrg2Count, setFullAccessPassOrg2Count] = useState();
    const [fullAccessPassOrg3Count, setFullAccessPassOrg3Count] = useState();
    const [fullAccessPassOrgOtherCount, setFullAccessPassOrgOtherCount] = useState();

    const [dayPassOrg1Name, setDayPassOrg1Name] = useState();
    const [dayPassOrg2Name, setDayPassOrg2Name] = useState();
    const [dayPassOrg3Name, setDayPassOrg3Name] = useState();
    const [dayPassOrg1Count, setDayPassOrg1Count] = useState();
    const [dayPassOrg2Count, setDayPassOrg2Count] = useState();
    const [dayPassOrg3Count, setDayPassOrg3Count] = useState();
    const [dayPassOrgOtherCount, setDayPassOrgOtherCount] = useState();

    const [gameStreamDates, setGameStreamDates] = useState([]);
    const [gameStreamValues, setGameStreamValues] = useState([]);

    const intialCustomDateRange = {
        startDate: getYesterday(),
        endDate: new Date(),
        key: 'selection'
    };
    const [customDateSelection, setCustomDateSelection] = useState([intialCustomDateRange]);

    const dateRangePickerRef = useRef();

    let isMonthlyTimeFrame = false;

    const setLastWeek = () => {
        if (!lastWeekSelected) {
            setLastWeekSelected(true);
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setCustomDateRangeSelected(false)
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
        }
        setLastWeekDates();
        setShowDateRangePicker(false);
    };
    let lastWeekButtonClass = lastWeekSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setLastMonth = () => {
        if (!lastMonthSelected) {
            setLastMonthSelected(true);
            setLastWeekSelected(false)
            setLastQuarterSelected(false)
            setCustomDateRangeSelected(false)
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
        }
        setLastMonthDates();
        setShowDateRangePicker(false);
    };
    let lastMonthButtonClass = lastMonthSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setLastQuarter = () => {
        if (!lastQuarterSelected) {
            setLastQuarterSelected(true);
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setCustomDateRangeSelected(false)
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
        }
        setLastQuarterDates();
        setShowDateRangePicker(false);
    };
    let lastQuarterButtonClass = lastQuarterSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setCurrentYear = () => {
        if (!currentYearSelected) {
            setCurrentYearSelected(true);
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setAllTimeSelected(false)
            setCustomDateRangeSelected(false)
        }
        setCurrentYearDates();
        setShowDateRangePicker(false);
    };
    let currentYearButtonClass = currentYearSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setAllTime = () => {
        if (!allTimeSelected) {
            setAllTimeSelected(true)
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setCurrentYearSelected(false);
            setCustomDateRangeSelected(false)
        }
        setAllTimeDates();
        setShowDateRangePicker(false);
    };
    let allTimeButtonClass = allTimeSelected ? "text-danger btn  bg-white" : "text-dark btn";

    const setCustomDateRange = () => {
        if (!customDateRangeSelected) {
            setCustomDateRangeSelected(true);
            setCustomDates(intialCustomDateRange);
            setLastWeekSelected(false)
            setLastMonthSelected(false)
            setLastQuarterSelected(false)
            setCurrentYearSelected(false)
            setAllTimeSelected(false)
        }
        setShowDateRangePicker(!showDateRangePicker);
    };
    let customDateRangeButtonClass = customDateRangeSelected ? "text-danger btn  bg-white" : "text-dark btn";

    function setLastWeekDates() {
        const {firstDay, lastDay} = getDatesOfLastWeek();

        setStartDate(firstDay);
        setEndDate(lastDay);
    }

    function setLastMonthDates() {
        const date = new Date();

        const firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        // Really zero hours on the first day of this month
        const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);

        setStartDate(firstDayPrevMonth);
        setEndDate(lastDayPrevMonth);
    }

    function setLastQuarterDates() {
        const now = new Date();
        let currentQuarter = Math.floor(now.getMonth() / 3 + 1);
        const currentYear = now.getFullYear();

        let qtrStartDate;
        let qtrEndDate;

        switch (currentQuarter) {
            case 1:
                // Oct 1 - end of Dec 31 previous year
                qtrStartDate = new Date(currentYear - 1, 9, 1);
                qtrEndDate = new Date(currentYear, 0, 1);
                break;
            case 2:
                // Jan 1 - end of Mar 31
                qtrStartDate = new Date(currentYear, 0, 1);
                qtrEndDate = new Date(currentYear, 3, 1);
                break;
            case 3:
                // Apr 1 - end of Jun 30
                qtrStartDate = new Date(currentYear, 3, 1);
                qtrEndDate = new Date(currentYear, 6, 1);
                break;
            case 4:
                // Jul 1 - end of Sep 30
                qtrStartDate = new Date(currentYear, 6, 1);
                qtrEndDate = new Date(currentYear, 9, 1);
                break;
        }

        setStartDate(qtrStartDate);
        setEndDate(qtrEndDate);
    }

    function setCurrentYearDates() {
        const currentYearStart = new Date(new Date().getFullYear(), 0, 1);
        const currentYearEnd = getTomorrow();

        setStartDate(currentYearStart);
        setEndDate(currentYearEnd);
    }

    function setAllTimeDates() {
        const allTimeStart = new Date(2022, 0, 1);
        const now = getTomorrow();

        setStartDate(allTimeStart);
        setEndDate(now);
    }

    function setCustomDates(pickerSelection) {
        setCustomDateSelection([pickerSelection]);

        setStartDate(pickerSelection.startDate);
        setEndDate(getBeginningOfNextDay(pickerSelection.endDate));
    }

    function getOrganizationEventPurchases(startDate, endDate) {
        API.getOrganizationEventPurchases(startDate, endDate, authCtx.token).then((data) => {
            const countsByOrg = data.orgEventsCount;
            const topOrgs = countsByOrg.slice(0,3);
            const otherOrgs = countsByOrg.slice(3);

            const resultNames = topOrgs.map(org => org.name);
            const resultValues = topOrgs.map(org => org.count);

            const otherTotal = otherOrgs.reduce((sum, item) => sum + item.count, 0);
            if (otherTotal) {
                resultNames.push('Other');
                resultValues.push(otherTotal);
            }

            setOrgNames(resultNames);
            setOrgPassCounts(resultValues);
        });
    }

    function getFancastOrgFullAccessPassPurchases(startDate, endDate) {
        API.getFancastOrgFullAccessPassPurchases(startDate, endDate, authCtx.token).then((data) => {
            const orgEventCounts = data.organizations;
            const dates = orgEventCounts.map(data => toDayAndMonth(data.purchasedAt));
            const orgNames = orgEventCounts.map(data => data.organization).filter(unique);

            const eventPassTotal = orgEventCounts.reduce((sum, item) => sum + item.count, 0);
            setTotalEventPass(eventPassTotal);

            setFullAccessPassOrg1Name(orgNames[0]);
            setFullAccessPassOrg2Name(orgNames[1]);
            setFullAccessPassOrg3Name(orgNames[2]);

            const org1Counts = orgEventCounts.map(data => data.organization === orgNames[0] ? data.count : 0);
            const org2Counts = orgEventCounts.map(data => data.organization === orgNames[1] ? data.count : 0);
            const org3Counts = orgEventCounts.map(data => data.organization === orgNames[2] ? data.count : 0);
            const orgOtherCounts = orgEventCounts.map(data => (data.organization !== orgNames[0] && data.organization !== orgNames[1] && data.organization !== orgNames[2]) ? data.count : 0);

            setFullAccessPassOrg1Count(org1Counts);
            setFullAccessPassOrg2Count(org2Counts);
            setFullAccessPassOrg3Count(org3Counts);
            setFullAccessPassOrgOtherCount(orgOtherCounts);

            setEventPassDates(dates);
        });
    }

    function getFancastOrgDayPassPurchases(startDate, endDate) {
        API.getFancastOrgDayPassPurchases(startDate, endDate, authCtx.token).then((data) => {
            const orgEventCounts = data.organizations;
            const dates = orgEventCounts.map(data => toDayAndMonth(data.purchasedAt));
            const orgNames = orgEventCounts.map(data => data.organization).filter(unique);

            const dayPassTotal = orgEventCounts.reduce((sum, item) => sum + item.count, 0);
            setTotalDayPass(dayPassTotal);

            setDayPassOrg1Name(orgNames[0]);
            setDayPassOrg2Name(orgNames[1]);
            setDayPassOrg3Name(orgNames[2]);

            const org1Counts = orgEventCounts.map(data => data.organization === orgNames[0] ? data.count : 0);
            const org2Counts = orgEventCounts.map(data => data.organization === orgNames[1] ? data.count : 0);
            const org3Counts = orgEventCounts.map(data => data.organization === orgNames[2] ? data.count : 0);
            const orgOtherCounts = orgEventCounts.map(data => (data.organization !== orgNames[0] && data.organization !== orgNames[1] && data.organization !== orgNames[2]) ? data.count : 0);

            setDayPassOrg1Count(org1Counts);
            setDayPassOrg2Count(org2Counts);
            setDayPassOrg3Count(org3Counts);
            setDayPassOrgOtherCount(orgOtherCounts);

            setDayPassDates(dates);
        });
    }

    function getGameStreamData(startDate, endDate) {
        API.getGameStreamData(startDate, endDate, authCtx.token).then((data) => {
            const gameStreamData = data.dates;

            const totalDays = Math.round(DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), ["days"]).toObject().days);
            isMonthlyTimeFrame = totalDays > 365;

            const resultGameStreamDates = gameStreamData.map(data => formatISODateForDisplay(data.date));
            setGameStreamDates(resultGameStreamDates);

            const totalGameStreams = gameStreamData.reduce((sum, data) => sum + data.streams, 0);
            setTotalGameStreamCount(totalGameStreams);

            const resultGameStreamValues = gameStreamData.map(data => data.streams);
            setGameStreamValues(resultGameStreamValues);
        });
    }

    function formatISODateForDisplay(isoDate) {
        const date = new Date(isoDate);
        return isMonthlyTimeFrame ? toMonthAndYear(date) : toDayAndMonth(date);
    }

    function unique(value, index, array) {
        return array.indexOf(value) === index;
    }

    let chartTitleOrg = 'Org Event Purchases'
    let showSubtitleOrg = !gameStreamValues.length;

    useEffect(() => {
        getOrganizationEventPurchases(startDate, endDate);
        getFancastOrgFullAccessPassPurchases(startDate, endDate);
        getFancastOrgDayPassPurchases(startDate, endDate);
        getGameStreamData(startDate, endDate);
    }, [startDate, endDate])

    function handlePageClick(e) {
        // If the user clicks outside the date range picker, dismiss the date range picker.
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(e.target)) {
            setShowDateRangePicker(false);
        }
    }

    function getTimeFrameContent() {
        let currentYear = getCurrentYear();
        let displayedEndDate = getPreviousDay(endDate);
        let showFullTimeFrameDates = startDate.getFullYear() < currentYear || displayedEndDate < currentYear;
        let timeFrameStartDateContent = showFullTimeFrameDates ? toFullDateString(startDate) : toDayAndMonth(startDate);
        let timeFrameEndDateContent = showFullTimeFrameDates ? toFullDateString(displayedEndDate) : toDayAndMonth(displayedEndDate);
        return <>{timeFrameStartDateContent} - {timeFrameEndDateContent}</>;
    }

    function getDateRangePickerContent() {
        if (showDateRangePicker) {
            return <>
                <div ref={dateRangePickerRef} className="col-4 rounded-4 bg-warning" style={{
                    backgroundColor: '#000',
                    marginLeft: '5%',
                    margin: '10px',
                    width: '35%',
                    alignItems: 'flex-end',
                    justifyContent: 'right',
                }}>
                    <DateRange
                      inline
                      editableDateInputs={true}
                      onChange={item => setCustomDates(item.selection)}
                      moveRangeOnFirstSelection={true}
                      ranges={customDateSelection}
                      endDate={endDate}
                      months={2}
                      direction="horizontal"
                      rangeColors={['#696974']}
                      weekStartsOn={0}
                      showPreview={true}
                      showDateDisplay={true}
                      autoFocus={true}
                      dragSelectionEnabled={true}
                      staticRanges={[]}
                    />
                </div>
            </>;
        } else {
            return '';
        }
    }

    function OrgCount({name, count}) {
        return  <div className="col-sm py-1">
            <DataDisplay
              title={name}
              dataValue={count}
              dataText={'Weekly'}
              showLogo={false}
              valueColor={'#E31937'}
            />
        </div>
    }

    function getPurchasesByOrgContent() {
        const rows = [];
        for (let i = 0; i < orgNames.length; i++) {
            rows.push(<OrgCount name={orgNames[i]}
                                count={orgPassCounts[i]}
                                key={orgNames[i]}
                      />);
        }

        if (orgNames.length) {
            return <>{rows}</>;
        } else {
            return <div className="text-dark" style={{paddingBottom: '48px'}}>No data for interval</div>
        }
    }

    return (
        <div onClick={handlePageClick} style={{backgroundColor: 'white', height: '100%', alignContent: 'right', fontSize: '1.0em'}}>
            <div className="container">
                <h3 className="text-dark" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    marginBottom: '45px',
                    marginTop: '30px',
                    paddingLeft: '12px'
                }}>FanCast</h3>
                <h3 className="text-dark" style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    marginBottom: '8px',
                    paddingLeft: '12px'
                }}>Timeframe</h3>
                <div className="row pb-3">
                    <div className="col-8 py-1 " id="dateRange" hidden="">
                        <div>
                            <h3 className="text-dark py-2" style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                paddingLeft: '12px'
                            }}>{getTimeFrameContent()}</h3>
                        </div>
                        <div className="btn-toolbar col-md-12" role="toolbar">
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}} onClick={setLastWeek}
                                    aria-pressed="true" className={lastWeekButtonClass}>Last Week
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}} onClick={setLastMonth}
                                    aria-pressed="true" className={lastMonthButtonClass}>Last Month
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}} onClick={setLastQuarter}
                                    aria-pressed="true" className={lastQuarterButtonClass}>Last Quarter
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}} onClick={setCurrentYear}
                                    aria-pressed="true" className={currentYearButtonClass}>{getCurrentYear()}
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}}
                                    onClick={setAllTime}
                                    aria-pressed="true" className={allTimeButtonClass}>All Time
                            </button>
                            <button type="button" style={{fontFamily: 'Montserrat, sans-serif'}} onClick={setCustomDateRange}
                                    aria-pressed="true" className={customDateRangeButtonClass}>Custom
                            </button>
                        </div>
                        {getDateRangePickerContent()}
                    </div>

                    <br/><br/><br/>

                    <div className="col-sm py-1"/>
                    {/*<div className="col-sm py-1">*/}
                    {/*    <img*/}
                    {/*        src="https://img.favpng.com/18/3/17/app-icon-download-icon-essential-icon-png-favpng-GAajYzjixCMbMVVFAsYnVHkFy.jpg"*/}
                    {/*        height="25px"/>*/}
                    {/*    <button type="button" className="text-dark btn">Download</button>*/}
                    {/*</div>*/}
                </div>
            </div>
            <br/><br/>
            <div className="container text-light">
                <div className="row py-2">
                    <div className="col-sm py-1" style={{paddingLeft: '25px'}}>
                        <h3 className="text-dark" style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}>Purchases</h3>
                        <div className="col px-4 py-2">
                            <BigNumbers title={'Event Pass'} value={totalEventPass}/>
                            <BarChartStack
                                title={chartTitleOrg}
                                labels={eventPassDates}
                                data1={fullAccessPassOrg1Count}
                                data2={fullAccessPassOrg2Count}
                                data3={fullAccessPassOrg3Count}
                                data4={fullAccessPassOrgOtherCount}
                                tooltip={'# of Purchases by Org'}
                                org1={fullAccessPassOrg1Name}
                                org2={fullAccessPassOrg2Name}
                                org3={fullAccessPassOrg3Name}
                                org4={'Other'}
                            />
                        </div>
                    </div>

                    <div className="col-sm py-1">
                        <h3 className="text-dark" style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}></h3>
                        <div className="px-4 py-2 pt-4">
                            <BigNumbers title={'Day Pass'} value={totalDayPass}/>
                            <BarChartStack
                                title={chartTitleOrg}
                                labels={dayPassDates}
                                data1={dayPassOrg1Count}
                                data2={dayPassOrg2Count}
                                data3={dayPassOrg3Count}
                                data4={dayPassOrgOtherCount}
                                tooltip={'# of Purchases by Org'}
                                org1={dayPassOrg1Name}
                                org2={dayPassOrg2Name}
                                org3={dayPassOrg3Name}
                                org4={'Other'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-light">
                <div className="row pt-3">
                    <h3 className="text-dark" style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: '32px',
                        fontWeight: 'bold',
                        marginBottom: '25px',
                        marginTop: '40px'
                    }}>Purchases by Organization</h3>
                    {getPurchasesByOrgContent()}
                </div>
                <div className="row py-2">
                    <div className="col-sm py-1">
                        <h3 className="text-dark" style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: '24px',
                            fontWeight: 'bold'
                        }}>Game Streams</h3>
                        <div className="px-4 py-2">
                            <BigNumbers title={'Total Streams'} value={totalGameStreamCount}/>
                            <BarChart title={chartTitleOrg}
                                      labels={gameStreamDates}
                                      data={gameStreamValues}
                                      tooltip={'Game streams'}/>
                        </div>
                    </div>
                    <div className="col-sm py-1 pt-5">
                    </div>
                </div>
            </div>
            <Modal/>
            <br/><br/><br/><br/>
        </div>
    )
}

export default FanCast;